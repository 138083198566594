@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Voltaire&display=swap');
@font-face {
    font-family: 'Shelline';
    src: url('./fonts/shelline.ttf') format('woff2'),
         url('./fonts/shelline.ttf') format('woff');
    
}

h1, .shellinefont {
    font-family: 'Shelline', cursive; 
}
h1{

}

h2, .navfont {
    font-family: "Voltaire", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  

